.local {
    text-align: center;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.local p {
    padding:1rem 0 1rem;
}
.actions button {
    margin:0 5px;
}
input {
    margin: 5px;
    border-radius: 7px;
    height: 2rem;
    font-size: 1.4rem;
}
.info {
    font-family: Roboto;
    font-size: 1.2rem;
    background-color: rgba(255,137,147,0.82);
    box-shadow: 2px 3px 15px darkred;
    width: 30vw;
    margin:10px;
    height: fit-content;
    text-align: center;
}
@media only screen and (max-width: 560px){
    .info {
        font-size: 1rem;
        padding-bottom: 25px;
    }
    .actions button {
        margin:10px 5px;
    }
}