.container {
    color: white;
    border-radius: 6px;
    padding: 3rem;
    margin: 1rem auto 2rem;
    width: 80vh;
    background-color: #162b2b;
    font-size: 1.2rem;


}
.text {
    text-indent: 2rem;
    letter-spacing: 0.2rem;
}
.text ul {
    margin-bottom: 10px;
}
.data {
    display: flex;
    flex-direction: column;
    gap:1rem;
}

@media only screen and (max-width:576px){
    .container {
        margin:0;
        border-radius: 0;
        font-size: 1rem;
        padding: 0;
    }
    .text {
        text-indent: 5px;
        letter-spacing:0;
        padding: 20px;
        margin:0 40px;
    }
    .data {
        padding: 20px;
    }
}
@media only screen and (max-height: 600px) {
    .container {
        margin:0;
        border-radius: 0;
        font-size: 1rem;
        padding: 0;
        width: 100%;
    }
    .text {
        text-indent: 5px;
        letter-spacing:0;
        padding: 20px;
        margin:0 40px;
    }
    .data {
        padding: 20px;
    }
}