.list {
    list-style: none;
    margin: 0 0 4rem;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.actions {
    display:flex;
}
.actions button  {
    margin:10px 20px;
    animation: btn 1500ms ease-in-out forwards;
}
.info {
    font-family: "Indie Flower";
    font-size: 2rem;
    margin-top: 20px;
}


@keyframes btn  {
    from {opacity: 0}
    to {opacity: 1}

}
@media only screen and (max-width: 900px){
    .list {
        margin:0 1rem;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: auto;
    }
}
@media only screen and (max-width: 480px){
    .actions {
flex-direction: column    }
}