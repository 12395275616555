.container {
    display: flex;
    flex-direction: column;
}
.addTaskForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
label {
    font-size: 1.25rem;
    margin:2rem 0 1rem;
}
.addTaskForm input {
    margin: 5px;
    border-radius: 7px;
    height: 2rem;
    width: 50%;
    font-size: 1.4rem;
    text-align: center;
}
.addTaskForm textarea {
    width: 70%;
    height: 50%;
    border-radius: 10px;
    font-size: 1.4rem;
    text-align: center;
    margin-bottom: 10px;
}
.addTaskForm textarea:focus {
    background-color: #88dfdf;
    color:#162b2b;

}
.addTaskForm input:focus {
    background-color: #88dfdf;
    color:#162b2b;
    height: 2rem;
    font-size: 1.4rem;
}
.errorInfo {
    background-color: rgba(255,137,147,0.82);
    width: 30vw;
    animation: info-appear 0.3s ease-in forwards;
    margin: 5rem auto 2rem auto;
    padding: 10px 15px;
    text-align: center;
    box-shadow: 2px 3px 15px darkred;


}
.info {
    background-color: #11acac;
    width: 70%;
    align-self: center;
    font-size: 1.1rem;
    font-family: "Calisto MT";
    padding: 10px 15px;
    border-radius: 6px;
    text-align: center;
    animation: errorInfo-appear 0.3s ease-in forwards;
}
@keyframes errorInfo-appear {
    from {
        opacity: 0;
        transform: translateY(10vh);
    }
    to {
        opacity:100;
        transform: translateY(0);

    }
}
@media only screen and (max-width: 576px) {

    .errorInfo {
        width: auto;
        margin-bottom: 20px;
        padding: 10px 15px;


    }
    .info {
        width: auto;
        font-family: "Calisto MT";
        padding: 5px 10px;


    }
}
