.container {
    display: flex;
    flex-direction: column;
}
.loginForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.loginForm input {
    margin: 5px;
    border-radius: 10px;
    height: 2rem;
    font-size: 1.4rem;
}
.loginForm input:focus {
    background-color: #88dfdf;
    color:#008080;
    height: 2rem;
    font-size: 1.4rem;
}

.errorInfo {
    background-color: rgba(255,137,147,0.82);
    width: 50vh;
    animation: errorInfo-appear 0.3s ease-in forwards;
    padding: 10px 15px;
    box-shadow: 2px 3px 15px darkred;
    text-align: center;

}
@keyframes errorInfo-appear {
    from {
        opacity: 0;
        transform: translateY(10vh);
    }
    to {
        opacity:100;
        transform: translateY(0);

    }
}

