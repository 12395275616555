.footer {
    position: fixed;
    bottom:0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #008080;
    color: #FFF;
    height: 3rem;
    padding: 1em;
    width: 100%;
}
.author {
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.logo {
    width: 3rem;
    height: 2rem;
}
@media only screen and (max-height: 400px) {
 .footer {

 }
}