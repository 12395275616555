.headerContainer {
    position: sticky;
    top:0;
    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #008080;
    color: #FFF;
    height: 50px;
    padding: 1em;

}
h2 {
    font-family: 'Indie Flower';
    font-stretch: extra-condensed;
    font-style: oblique;
;

}
.menu {
    list-style-type: none;
    display: flex;
    flex-direction: row;
}
.menu > li {
    overflow: hidden;
    margin: 0 1rem;

}

.menuButtonContainer {
    height: 100%;
    width: 30px;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: none;
}
#menuToggle {
    display: none;
}
.menuButton,
.menuButton::before,
.menuButton::after {
    background-color: #162b2b;
    width: 30px;
    height: 5px;
    display: block;
    position: absolute;
    transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
    border-radius: 2px;
}

.menuButton::after {
    content:'';
    margin-top:8px;
}
.menuButton::before {
    content:'';
    margin-top:-8px;
}

#menuToggle:checked + .menuButtonContainer .menuButton::before {
    margin-top: 0;
    transform: rotate(405deg);
}

#menuToggle:checked + .menuButtonContainer .menuButton {
    background: rgba(255, 255, 255, 0);

}

#menuToggle:checked + .menuButtonContainer .menuButton::after {
    margin-top: 0;
    transform: rotate(-405deg);
}
.activated{
    color:#162b2b;
    animation: activeMenu 0.6s ease-in-out forwards;
}
@keyframes activeMenu {
    0% {
        color:#162b2b;
    }
    50% {
        color:#FFFFFF;
        font-weight: bolder;

    }
    100% {
        color:#162b2b;
        font-weight: bold;

    }
}
@media (max-width: 900px){
    .menuButtonContainer {
        display: flex;
    }
    .menu {
        position: absolute;
        top: 0;
        margin-top: 50px;
        left: 0;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        z-index: 9999;
    }
    #menuToggle ~ .menu li {
        height: 0;
        margin: 0;
        padding: 0;
        border: 0;
        transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
    }
    #menuToggle:checked ~ .menu li {
        border: 1px solid #333;
        height: 2.5em;
        padding: 0.5em;
        transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
    }
    .menu > li {
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 0.5em 0;
        width: 100%;
        color: white;
        background-color: #222;
    }
    .menu >li:not(:last-child){
        border-bottom: 1px solid #444;

    }
    @keyframes activeMenu {
        0% {
            color:#FFFFFF;
        }
        50% {
            color:#162b2b;
            font-weight: bolder;

        }
        100% {
            color:#FFFFFF;
            font-weight: bold;

        }
    }
}

