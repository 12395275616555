.userCard {
   display: flex;
    flex-direction: column;
    margin-top: 40px;
    background-color: #c2e7f0;
    color:#162b2b;
    padding: 50px 70px;
    border-radius: 13%;
    row-gap: 20px;
}

.welcome {
    font-size: 2em;

}
.userData {
    display: flex;
    flex-direction: column;

}

.userData p {
    display: flex;
    justify-content: space-between;
    gap:20px;
    padding:20px 50px;
}
.actions {
        display: flex;
        justify-content: space-around;
}

@media only screen and (max-width: 576px){
    .userCard {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin:0;
        background-color: #162b2b;
        color:white;
        padding:10px 0;
        row-gap: 2px;
        width: 100%;
    }
    .userData {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

    }
    .actions {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

}

