.item {
    margin: 1rem 0;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: center;
    border-radius: 6px;
    background-color: #c2e7f0;
    width: 90vh;
    box-shadow: #162b2b 1px 8px 11px;
    box-sizing: content-box;
    transition: all 250ms ease;
    position:relative;
    word-break: break-all;
}
.item::after{
    transition: all 250ms ease;
    content: "";
    position: absolute;
    display: block;
    bottom:0;
    left:0;
    width: 0;
    height: 0.2rem;
    background-color: #162b2b;
}

.item:hover::after {
    width: 100%;
}

.title {
    border-right: 2px solid #162b2b;
    padding-right: 55px;
    margin: 0;
    text-align: left;
    font-size: 1.5rem;
    color: #000000;
}
.content {
    padding-left: 50px;
    font-family: 'Indie Flower';
    font-size: 1.3rem;
}

.actions {
    display: flex;
    padding-left: 30px;
    align-items: center;
    justify-content: center;
}
.actions button {
    margin: 0;
}

.actions img {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    margin: 0.2rem;
    opacity: 0.6;
}
.actions img:hover {
    opacity: 1;
    transform: scaleX(-1);
}
.isDone {
    background-color: #cccccc;
    border:2px solid #162b2b;
    text-decoration: #162b2b line-through;
    opacity:0.5;
}

.isDone .title p {
    display: none;
}
.isDone:hover::after{
   width: 0;
}

@media only screen and (max-width: 900px) {


    .item {
        margin: 1rem 0;
        padding: 0;
        width: 100%;
        min-height: 10vh;

    }
    .title {
        border-right: 2px solid #162b2b;
        margin: 0 0 0 20px;
        text-align: left;
        font-size: 0.8rem;
        color: #000000;
    }
    .content {
        margin:0;
    }


    .actions {
        display: flex;
        padding:10px;
        align-items: center;
        justify-content: center;
    }


    .actions img {
        width: 2rem;
        height: 2rem;
        cursor: pointer;
        opacity: 0.6;
    }
    .actions img:hover {
        opacity: 1;
        transform: scaleX(-1);
    }

}
@media only screen and (max-width: 400px){

    .item {
        margin: 1rem 0;
        padding: 0;
        width: 100%;
        min-height: 10vh;

    }
    .title {
       display: none;
    }
    .content {
        margin:0;
    }


    .actions {
        display: flex;
        padding:10px;
        align-items: center;
        justify-content: center;
    }


    .actions img {
        width: 2rem;
        height: 2rem;
        cursor: pointer;
        opacity: 0.6;
    }
    .actions img:hover {
        opacity: 1;
        transform: scaleX(-1);
    }
}