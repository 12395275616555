
* {
  box-sizing: border-box;
  margin:0;
  padding:0;
}

body {


}
#root {
  font-family: sans-serif;
  padding:0;
  margin: 0;
  animation: body-appear 1.5s ease-out forwards;
  width: 100%;
}
.mainContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin:0 auto 4rem;

}
.modal {
  position: fixed;
  z-index: 100;
  overflow: hidden;
  background-color: #c2e7f0;
  top:30vh;
  left:50vh;
  width: 50%;
  height: 35%;
  border-radius: 7rem;
}
a {
  text-decoration: none;
  color: #c2e7f0;
}


.animateElement {
  animation: animate-element 0.5s ease-in forwards;

}
@keyframes body-appear {
  from {
    opacity: 0;
  }
  to {
    opacity:100
  }
}
@keyframes animate-element {
  from {
    opacity: 0;
  }
  to {
    opacity:100
  }
}
@media only screen and (max-width: 1000px){
  .modal {
    position: absolute;
    top:1vh;
    left:1vw;
    z-index: 100;
    overflow: hidden;
    background-color: #c2e7f0;
    width: 90%;
    height: 60%;
  }
}
@media only screen and (max-width: 576px){
  body {
  }
  .mainContainer {
    width: auto;
    padding: 0;
  }
  .modal {
    position: absolute;
    top:1vh;
    left:5vw;
    z-index: 100;
    overflow: hidden;
    background-color: #c2e7f0;
    width: 90%;
    height: 45%;
  }
  #root {
    font-family: sans-serif;
    padding:0;
    margin: 0;
    animation: body-appear 1.5s ease-out forwards;
    width: 100vw;

  }
}
@media only screen and (max-height: 400px) {
.modal {
  height: 100%;
  width: 100%;
}
}
